// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import "@hotwired/turbo-rails";
import "chartkick/chart.js";

import "channels";

Rails.start();
ActiveStorage.start();

window.Stimulus = Application.start();
Stimulus.debug = false; // Configure Stimulus development experience
const context = require.context("./controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));
